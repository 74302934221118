import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Import Firestore
import ContactModal from '../components/ContactModal';

const NavBar = ({ onLoginClick }) => {
  const [user, setUser] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [contactFormEndpoint, setContactFormEndpoint] = useState(""); // Dynamic endpoint

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return unsubscribe;
  }, []);

  // Fetch the contact form endpoint from Firestore
  useEffect(() => {
    const fetchContactFormEndpoint = async () => {
      try {
        const docRef = doc(firestore, "settings", "contactForm");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setContactFormEndpoint(docSnap.data().endpoint);
        }
      } catch (error) {
        console.error("Error fetching contact form endpoint:", error);
      }
    };

    fetchContactFormEndpoint();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
    });
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleContactModal = () => {
    setContactModalOpen((prev) => !prev);
  };

  return (
    <>
      <nav className="bg-white shadow-md fixed w-full z-10">
        <div className="container mx-auto px-4 flex items-center justify-between h-20">
          {/* Logo and Title */}
          <div className="flex items-center space-x-4">
            <Link to="/">
              <img
                src="https://res.cloudinary.com/bettercast/image/upload/v1728737295/businessdaddy/fezm6dxyqnbscofnz8el.png"
                alt="Business Daddy Logo"
                className="h-12"
              />
            </Link>
            <Link to="/">
              <span className="text-2xl font-semibold">Business Daddy Studio</span>
            </Link>
          </div>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex items-center space-x-8">
            <span
              onClick={toggleContactModal}
              className="text-gray-600 hover:text-gray-900 cursor-pointer"
            >
              Contact Us
            </span>
            <Link to="/guides" className="text-gray-600 hover:text-gray-900">
              Guides
            </Link>
          </div>

          {/* Mobile Hamburger Icon */}
          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="focus:outline-none text-gray-600 hover:text-gray-900"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Contact Us Modal */}
      {isContactModalOpen && (
        <ContactModal
          isOpen={isContactModalOpen}
          onClose={toggleContactModal}
          onSubmitEndpoint={contactFormEndpoint || "https://default-endpoint.com/api/contact"}
        />
      )}
    </>
  );
};

export default NavBar;