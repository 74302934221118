import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc, setDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase"; // Firestore
import CreateProjectForm from "./CreateProjectForm"; // The form component will be used inside the modal

export default function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null); // For editing
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [contactFormEndpoint, setContactFormEndpoint] = useState(""); // State for endpoint
  const [successMessage, setSuccessMessage] = useState(""); // Success message for endpoint update

  // Fetch all projects from Firestore
  const fetchProjects = async () => {
    const projectCollection = await getDocs(collection(firestore, "projects"));
    setProjects(projectCollection.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  // Fetch the contact form endpoint from Firestore
  const fetchContactFormEndpoint = async () => {
    try {
      const docRef = doc(firestore, "settings", "contactForm");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContactFormEndpoint(docSnap.data().endpoint);
      }
    } catch (error) {
      console.error("Error fetching contact form endpoint:", error);
    }
  };

  // Save the new endpoint to Firestore
  const handleSaveEndpoint = async () => {
    try {
      await setDoc(doc(firestore, "settings", "contactForm"), { endpoint: contactFormEndpoint });
      setSuccessMessage("Contact form endpoint updated successfully!");
      setTimeout(() => setSuccessMessage(""), 3000); // Clear the success message after 3 seconds
    } catch (error) {
      console.error("Error updating contact form endpoint:", error);
    }
  };

  // Delete project
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      await deleteDoc(doc(firestore, "projects", id));
      fetchProjects(); // Refresh projects
    }
  };

  // Edit project (opens modal)
  const handleEdit = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  // Add project (opens modal with empty form)
  const handleAddProject = () => {
    setSelectedProject(null); // Ensure it's a new project
    setShowModal(true);
  };

  useEffect(() => {
    fetchProjects();
    fetchContactFormEndpoint();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-32">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Projects</h1>
          <p className="mt-2 text-sm text-gray-700">List of all projects with editing and deletion options.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={handleAddProject}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            Add Project
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date Added</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {projects.map((project) => (
                    <tr key={project.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {project.title}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.dateAdded}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.status}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
                        <button
                          onClick={() => handleEdit(project)}
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(project.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Update Contact Form Endpoint */}
      <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold text-gray-900">Update Contact Form Endpoint</h2>
        <div className="mt-4">
          <label htmlFor="contact-endpoint" className="block text-sm font-medium text-gray-700">
            Contact Form Endpoint
          </label>
          <input
            type="text"
            id="contact-endpoint"
            value={contactFormEndpoint}
            onChange={(e) => setContactFormEndpoint(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <button
            onClick={handleSaveEndpoint}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Endpoint
          </button>
          {successMessage && <p className="mt-2 text-sm text-green-600">{successMessage}</p>}
        </div>
      </div>

      {/* Modal for editing/creating project */}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full">
              <button
                className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
                onClick={() => setShowModal(false)}
              >
                ×
              </button>
              <CreateProjectForm
                project={selectedProject}
                closeModal={() => setShowModal(false)}
                refreshProjects={fetchProjects}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}