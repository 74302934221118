import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { Calendar, Users, ExternalLink, ThumbsUp } from "lucide-react";
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { voteForProject, fetchVotesForProject } from '../../services/ProjectService'; // Import vote functions

const Card = ({ project }) => {
  const user = auth.currentUser; // Check user's authentication status
  const [hasVoted, setHasVoted] = useState(false); // Track if the user has voted
  const [currentVotes, setCurrentVotes] = useState(0); // Track current vote count
  const [loading, setLoading] = useState(true); // Loading state for votes
  const [alert, setAlert] = useState(false); // Show login alert

  const {
    img_src = '',
    title = 'Untitled Project',
    description = 'No description available.',
    features = [],
    status = 'Status Unknown',
    monthlyPrice = 'N/A',
    annualPrice = 'N/A',
    userCount = '0',
    releaseDate = '',
    link = '',
    youtubeEmbedUrl = '',
    id
  } = project || {}; // Ensure project is not undefined

  // Determine vote type based on status
  const getVoteType = (status) => {
    if (status === 'Released') return 'featuresVotes';
    if (status === 'In Development') return 'excitementVotes';
    if (status === 'Planned') return 'releaseVotes';
    return null;
  };

  // Fetch the votes for the project
  useEffect(() => {
    if (project) {
      const voteType = getVoteType(status);
      if (voteType) {
        fetchVotesForProject(project.id, voteType).then((voteData) => {
          setCurrentVotes(voteData[voteType] || 0); // Update vote count based on the status
          setHasVoted(voteData.users?.includes(user?.uid) || false); // Check if user has already voted
          setLoading(false);
        });
      }
    }
  }, [project, user]);

  const handleVote = async () => {
    if (!user) {
      setAlert(true); // Show alert if the user is not logged in
      return;
    }

    if (!hasVoted) {
      const voteType = getVoteType(status);

      if (voteType) {
        await voteForProject(project.id, user.uid, voteType); // Store the vote in Firestore
        setCurrentVotes(currentVotes + 1);
        setHasVoted(true); // Mark user as having voted
      }
    }
  };

  // Define vote button text based on status
  const getVoteButtonText = () => {
    if (status === 'Released') return 'Vote to Add More Features';
    if (status === 'Planned') return 'Vote to Make This the Next Release';
    if (status === 'In Development') return 'Vote to Show Your Excitement';
    return 'Vote';
  };

  return (
    <div className="bg-white rounded-xl shadow-2xl overflow-hidden mb-6 relative flex flex-col">
      {/* Dynamic Card Height */}
      <div className="w-full h-auto">
        <img
          src={img_src}
          alt={title}
          className="w-full h-64 object-cover border-b border-gray-200"
        />
      </div>

      <div className="p-8 flex-grow">
        {/* Title and Release Date */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-3xl font-bold text-gray-800">{title}</h2>
          {status === 'Released' && (
            <div className="flex items-center text-gray-600">
              <Calendar className="w-5 h-5 mr-2" />
              <span>{releaseDate ? `Released: ${releaseDate}` : 'No release date'}</span>
            </div>
          )}
        </div>


      {/* YouTube Video Embed */}
      {youtubeEmbedUrl && (
        <div className="mb-6 relative overflow-hidden rounded-lg" style={{ paddingBottom: '56.25%' }}>
          <iframe
            src={youtubeEmbedUrl}
            title="YouTube Video"
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      )}

        {/* Description */}
        <div className="text-gray-600 mb-4" dangerouslySetInnerHTML={{ __html: description }}></div>

        {/* Features */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800 mb-2">Key Features:</h3>
          <ul className="list-disc list-inside text-gray-600">
            {features.length > 0 ? (
              features.map((feature, index) => (
                <li key={index} className="text-gray-600">{feature}</li>
              ))
            ) : (
              <li>No features listed</li>
            )}
          </ul>
        </div>

        {/* Pricing Section */}
        <div className="flex flex-col space-y-4 mt-6">
          {(monthlyPrice === "" && annualPrice === "") ? (
            <div className="text-2xl font-bold text-gray-800">TBC</div>
          ) : (monthlyPrice === '-1' && annualPrice === '-1') ? (
            <div className="text-2xl font-bold text-gray-800">FREE</div>
          ) : (
            <div className="flex items-center space-x-4">
              {monthlyPrice !== '0' && (
                <div>
                  <span className="text-2xl font-bold text-gray-800">
                    {monthlyPrice !== '-1' ? `$${monthlyPrice}` : 'FREE'}
                  </span>
                  {monthlyPrice !== '-1' && <span className="text-gray-600">/month</span>}
                </div>
              )}
              {monthlyPrice !== '-1' && (
                <div className="text-gray-400">|</div>
              )}
              {annualPrice !== '0' && (
                <div>
                  <span className="text-2xl font-bold text-gray-800">
                    {annualPrice !== '-1' ? `$${annualPrice}` : 'FREE'}
                  </span>
                  {annualPrice !== '-1' && <span className="text-gray-600">/year</span>}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Status and Users Section */}
        <div className="flex justify-start items-center space-x-6 mt-4">
          <span className="text-sm font-semibold text-gray-600">Status: {status}</span>
          <div className="flex items-center text-gray-600">
            <Users className="w-5 h-5 mr-2" />
            <span>{userCount}+ users</span>
          </div>
        </div>
      </div>

      {/* Vote Button and Visit Website Link */}
      <div className="bottom-0 left-0 right-0 flex justify-between items-center p-6 bg-white border-t border-gray-200">
        {/* Website Link */}
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-500 hover:text-blue-600"
          >
            Visit Website
            <ExternalLink className="w-4 h-4 ml-2" />
          </a>
        )}


      </div>

      {/* Login Alert for Logged-Out Users */}
      {alert && (
        <div className="fixed top-16 right-0 z-50 p-4">
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">We want to hear from you!</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    To cast your vote, you need to make an account. Please log in to cast your vote.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;